import React, { useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails, setPermissionDetails } from "../../action/set-action-details";
import Logo from "../../assets/img/logoo-.png";
import { API_URL, HOTEL_CAPTION, HOTEL_NAME } from "../../context/connection-string";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {Renewal_date, currentDate, decryptData, encryptData, Audit} from "../../context/function";
function Login(props) {
    const [loginData, setLoginData] = useState({
        email_address: "",
        password: ""
    })


    const onHandleChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.id]: e.target.id === 'password' ? encryptData(e.target.value.trim()) : e.target.value.trim()
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (loginData.email_address === '') {
            toast.error("Please enter your registered email address");
            return false;
        }
        if (loginData.password === '') {
            toast.error("Please enter your password");
            return false;
        }
        toast.info("Please wait while trying to log you in...")
        await axios.post(`${API_URL}login/employee`, loginData)
            .then(res => {
                if (res.data.message === "success") {
                    toast.success("Login successful");
                    props.setOnLoginDetails(res.data.userData)
                    props.setOnPermissionDetails(res.data.permissionData)
                    Audit(res.data.userData[0]?.employee_id, 'Login to Payroll System');
                } else if (res.data.message === "invalid_login") {
                    toast.error("Invalid login credential. Please check and try again!")
                } else {
                    toast.error("Something went wrong. Please try again.")
                }
            })
            .catch(e => {
                console.log(e)
                toast.error("Please check your internet connection and try again!")
            })

    }

    return (
        <>
            <div className="d-flex flex-column flex-root">
                <style>
                    {`
                    body { 
                      background: linear-gradient(135deg, #025091, #0374b1);
                    }
                    [data-bs-theme="dark"] body { 
                      background: linear-gradient(135deg, #025091, #0374b1);
                    }
                  `}
            </style>
            <div className="d-flex flex-column flex-column-fluid flex-lg-row">
                    <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
                        <div className="d-flex flex-center flex-lg-start flex-column">
                            <a href="/" className="mb-7">
                                <img alt="Logo" src="https://payroll.teamcoded.com.ng/logo.png" alt={HOTEL_NAME} />
                            </a>
                            <h1 className="text-white fw-bold m-0" style={{fontSize: '30px'}}>TeamCoded Payroll Manager</h1>
                        </div>
                    </div>
                    <div className="d-flex flex-center w-lg-50 p-10">
                        <div className="card rounded-3 w-md-550px">
                            <div className="card-body d-flex flex-column p-10 p-lg-20 pb-lg-10">
                                <div className="d-flex flex-center flex-column-fluid pb-15 pb-lg-20">
                                    <form onSubmit={onSubmit} className="form w-100" noValidate="novalidate" id="kt_sign_in_form">
                                        <div className="text-center mb-11">
                                            <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
                                        </div>

                                        <div className="fv-row mb-8">
                                            <input type="email" placeholder="Employee Email Address" id="email_address" onChange={onHandleChange} autoComplete="off"
                                                   className="form-control bg-transparent" />
                                        </div>
                                        <div className="fv-row mb-3">
                                            <input type="password" placeholder="Password" id="password" onChange={onHandleChange}
                                                   autoComplete="off" className="form-control bg-transparent" />
                                        </div>
                                        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                            <div></div>
                                            <Link to={"/forgot-password"} className="link-primary">
                                                Forgot Password ?
                                            </Link>
                                        </div>
                                        <div className="d-grid mb-10">
                                            <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">
                                                {/*begin::Indicator label*/}
                                                <span className="indicator-label">Sign In</span>
                                                {/*end::Indicator label*/}
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        },
    };
};

export default connect(null, mapDispatchToProps)(Login);