import React, { useEffect } from "react";
import "jquery/dist/jquery.min.js";
import jsZip from "jszip";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "./style.css";
import $ from "jquery";
import { HOTEL_NAME } from "../../../../context/connection-string";
import { COMPANY_NHF_EMPLOYER_NO, COMPANY_NHF_NAME } from "../../../../context/function";

window.JSZip = jsZip;

export default function NHFDataTable({ tableID = "table", date, caption, groupCol = 0, isGrouping = false, title, header = [], body }) {
    useEffect(() => {
        if (!$.fn.DataTable.isDataTable(`#${tableID}`)) {
            $(document).ready(function () {
                setTimeout(function () {
                    $(`#${tableID}`).DataTable({
                        pagingType: "full_numbers",
                        pageLength: 20,
                        processing: true,
                        dom: "Bfrtip",
                        select: { style: "single" },
                        buttons: [
                            { extend: "pageLength", className: "btn btn-secondary bg-secondary" },
                            { extend: "copy", className: "btn btn-secondary bg-secondary" },
                            { extend: "csv", className: "btn btn-secondary bg-secondary" },
                            { extend: "excelHtml5", className: "btn btn-secondary bg-secondary" },
                            {
                                extend: "print",
                                customize: function (win) {
                                    $(win.document.body).find('h1, td a').remove();
                                    $(win.document.body).find('table').prepend(
                                        `<caption>
                                            <div class="flex-container" style="width: 100%; margin: auto;">
                                                <img src="https://payroll.teamcoded.com.ng/logo.png" width="100" height="80"/>
                                                <div class="flex-item2 text-uppercase">
                                                    <h4>
                                                        <b>${HOTEL_NAME}</b><br>
                                                        <small>${COMPANY_NHF_NAME}</small><br>
                                                        <small>FOR THE PERIOD: ${date}</small><br>
                                                        <small>EMPLOYER'S NUMBER: ${COMPANY_NHF_EMPLOYER_NO}</small>
                                                    </h4>
                                                </div>
                                            </div>
                                            <hr/>
                                        </caption>`
                                    );
                                    $(win.document.body).find('th, td').css({
                                        'white-space': 'pre-line',
                                        'text-justify': 'auto',
                                        'border': '1px solid #eeeeee'
                                    });
                                    $(win.document.body).find('table').addClass('compact').css({
                                        'font-size': '11px',
                                        'width': '100%',
                                        'font-family': 'Roboto, Helvetica, Arial, sans-serif',
                                        'font-weight': '400'
                                    });
                                },
                                className: "btn btn-secondary bg-secondary",
                            },
                        ],
                        order: isGrouping ? [[groupCol, 'asc']] : [],
                        drawCallback: isGrouping ? function () {
                            let api = this.api();
                            let rows = api.rows({ page: 'current' }).nodes();
                            let last = null;
                            api.column(groupCol, { page: 'current' }).data().each(function (group, i) {
                                if (last !== group) {
                                    $(rows).eq(i).before(
                                        `<tr class="group">
                                            <td colspan="14" style="background-color: rgba(110, 219, 110, 0.42);" align="center">
                                                <h4>${group}</h4>
                                            </td>
                                        </tr>`
                                    );
                                    last = group;
                                }
                            });
                        } : undefined,
                        columnDefs: [
                            isGrouping ? { visible: false, targets: groupCol } : {},
                            { targets: 0, render: (data, type, row, meta) => (type === "export" ? meta.row + 1 : data) }
                        ],
                    });
                }, 5000);
            });
        }
    }, [tableID, isGrouping, groupCol]);

    return (
        <table id={tableID} className="table caption-top myTable align-items-center justify-content-center mb-0" style={{ fontSize: '12px' }}>
            <thead>
            <tr style={{ border: '1px solid #cccccc' }}>
                {header.map((item, index) => (
                    <th key={index} className="text-uppercase text-dark text-sm font-weight-bolder bg-secondary opacity-7 ps-2">{item}</th>
                ))}
            </tr>
            </thead>
            <tbody>{body}</tbody>
        </table>
    );
}
