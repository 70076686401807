import React, { useEffect } from "react";
import logo from "../../../src/assets/img/logo.png";
import "jquery/dist/jquery.min.js";
import jsZip from "jszip";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "./data-table.css";
import $ from "jquery";
import { HOTEL_NAME } from "../../context/connection-string";

window.JSZip = jsZip;

export default function DataTable(props) {
    const today = new Date();
    const current_date = today.toISOString().split("T")[0];

    let tblID = props.tableID ?? "table";
    let report_date = props.date ?? current_date;
    let groupColumn = props.groupCol ?? 0;

    let isGrouping = props.isGrouping
        ? {
            order: [[groupColumn, "asc"]],
            drawCallback: function (settings) {
                let api = this.api();
                let rows = api.rows({ page: "current" }).nodes();
                let last = null;

                api.column(groupColumn, { page: "current" }).data().each(function (group, i) {
                    if (last !== group) {
                        $(rows)
                            .eq(i)
                            .before(
                                `<tr class="group">
                                      <td colspan="14" style="background-color: rgba(110, 219, 110, 0.42);" align="center">
                                          <h4>${group}</h4>
                                      </td>
                                  </tr>`
                            );
                        last = group;
                    }
                });
            },
        }
        : {};

    let groupColumnVisibility = props.isGrouping
        ? [{ visible: false, targets: groupColumn }]
        : [];

    useEffect(() => {
        if (!$.fn.DataTable.isDataTable(`#${tblID}`)) {
            $(document).ready(function () {
                setTimeout(() => {
                    $(`#${tblID}`).DataTable({
                        pagingType: "full_numbers",
                        pageLength: 20,
                        processing: true,
                        dom: "Bfrtip",
                        select: {
                            style: "single",
                        },
                        buttons: [
                            { extend: "pageLength", className: "btn btn-secondary bg-secondary" },
                            { extend: "copy", className: "btn btn-secondary bg-secondary" },
                            { extend: "csv", className: "btn btn-secondary bg-secondary" },
                            { extend: "excelHtml5", className: "btn btn-secondary bg-secondary" },
                            {
                                extend: "print",
                                customize: function (win) {
                                    $(win.document.body)
                                        .find("h1")
                                        .remove();
                                    $(win.document.body)
                                        .find("td a")
                                        .remove();

                                    $(win.document.body)
                                        .find("table")
                                        .prepend(`
                                            <caption>
                                                <div style="text-align: center; width: 100%; margin: auto;">
                                                    <img src="https://payroll.teamcoded.com.ng/logo.png" width="80" height="80"/>
                                                    <br>
                                                    <h3>${HOTEL_NAME} 
                                                        <small><br> ${props.title ?? "Report"} 
                                                        <br> ${report_date}</small>
                                                    </h3>
                                                </div>
                                            </caption>
                                        `);

                                    $(win.document.body)
                                        .find("th, td")
                                        .css({
                                            "white-space": "pre-line",
                                            "text-align": "left",
                                            border: "1px solid #eeeeee",
                                        });

                                    $(win.document.body)
                                        .find("table")
                                        .addClass("compact")
                                        .css({
                                            fontSize: "11px",
                                            width: "100%",
                                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                            fontWeight: "400",
                                        });
                                },
                                className: "btn btn-secondary bg-secondary",
                            },
                        ],
                        ...isGrouping,
                        fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                            let index = iDisplayIndexFull + 1;
                            $("td:first", nRow).html(index);
                            return nRow;
                        },
                        lengthMenu: [
                            [10, 20, 30, 50, -1],
                            [10, 20, 30, 50, "All"],
                        ],
                        columnDefs: [
                            ...groupColumnVisibility,
                            {
                                targets: 0,
                                render: function (data, type, row, meta) {
                                    return type === "export" ? meta.row + 1 : data;
                                },
                            },
                        ],
                    });
                }, 5000);
            });
        }
    }, [tblID]);

    return (
        <table
            id={tblID}
            className="table caption-top myTable align-items-center justify-content-center mb-0"
            style={{ fontSize: "12px" }}
        >
            {props.caption && (
                <caption>
                    <h1 className="text-end">{props.caption}</h1>
                </caption>
            )}
            <thead>
            <tr style={{ border: "1px solid #cccccc" }}>
                {props.header.length > 0 &&
                    props.header.map((item, index) => (
                        <th
                            key={index}
                            className="text-uppercase text-dark text-sm font-weight-bolder bg-secondary opacity-7 ps-2"
                        >
                            {item}
                        </th>
                    ))}
            </tr>
            </thead>
            <tbody>{props.body}</tbody>
        </table>
    );
}
