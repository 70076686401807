import swal from "sweetalert";
import {API_URL, HOTEL_CODE} from "./connection-string";
import axios from 'axios';

const CryptoJS = require("crypto-js");
export const NoImage = require("../assets/img/no-image.jpg");

export const ProjectName = 'TeamCoded';


//Client Credentials
export const COMPANY_TIN_NUMBER = '6500219040';
export const COMPANY_PFA = 'PENSION ALLIANCE LIMITED';
export const COMPANY_RECEIVING_BANK = 'UBA';
export const COMPANY_RECEIVING_ACCOUNT_NO = '1005385521';
export const COMPANY_PENSION_EMPLOYER_CODE = 'PRSCH0003005';
export const COMPANY_SORT_CODE = '033153665';
export const COMPANY_NHF_EMPLOYER_NO = '020018800-9';
export const COMPANY_NHF_NAME = 'FEDERAL MORTGAGE BANK (NATIONAL HOUSING FUND (DEGREE 3, 1992))';
export const COMPANY_NHIS = 'SALUS TRUST LIMITED';
export const COMPANY_NHIS_ACCOUNT_NAME = 'FIDELITY BANK';
export const COMPANY_NHIS_ACCOUNT_NUMBER = '4110008414';
export const COMPANY_NSITF_TITLE = "NIGERIA SOCIAL INSURANCE TRUST FUND (NSITF) <br/> (EMPLOYEEE'S COMPENSATION ACT, 2010) <br/>  EMPLOYER'S SCHEDULE OF PAYMENTS (ACTUAL) <br/>  (SECTION 40(1)(a) OF ACT)";


export const formatDate = (date) => {
    let d = new Date(date), month = "" + (d.getMonth() + 1), day = "" + d.getDate(), year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
};

export const currencyConverter = (amount) => {
    const formatter = new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
    });
    return !amount ? formatter.format(0) : formatter.format(amount);
};

export const moneyFormat = (amount) => {
    // Check if the provided amount is a number
    if (typeof amount !== 'number') {
        return 'Invalid input';
    }

    // Format the number with two decimal places and commas as thousand separators
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};


export const removeTimeFromDate = (date) => {
  if (date !== null) {
      const user_date = new Date(date);
      const day = user_date.getDate() < 10 ? "0" + user_date.getDate() : user_date.getDate();
      const month = user_date.getMonth() < 10 ? "0" + (user_date.getMonth()+1) : user_date.getMonth()+1;
      return `${user_date.getFullYear()}-${month}-${day}`
  }else
      return "--"
}
export const extractTimeFromDateTime = (date) => {
  if (date !== null) {
      const user_date = new Date(date);
      return `${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}`
  }else
      return "--"
}

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const formatDateAndTime = (date, option) => {
    if (date !== null) {
        const user_date = new Date(date);
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day_name = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        const day_id = parseInt(user_date.getDay());
        const day =
            user_date.getDate() < 10
                ? "0" + user_date.getDate()
                : user_date.getDate();
        const hour =
            user_date.getHours() < 10
                ? "0" + user_date.getHours()
                : user_date.getHours();
        const min =
            user_date.getMinutes() < 10
                ? "0" + user_date.getMinutes()
                : user_date.getMinutes();
        const sec =
            user_date.getSeconds() < 10
                ? "0" + user_date.getSeconds()
                : user_date.getSeconds();

        let date_string = "";
        if (option === "date_and_time")
            date_string = `${day}-${monthNames[user_date.getMonth()]
                }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
        else if (option === "date")
            date_string = `${day}-${monthNames[user_date.getMonth()]
                }-${user_date.getFullYear()}`;
        else if (option === "day") date_string = day;
        else if (option === "full_month")
            date_string = monthNames[user_date.getMonth()];
        else if (option === "short_month")
            date_string = monthNamesShort[user_date.getMonth()];
        else if (option === "year_only") date_string = user_date.getFullYear();
        else if (option === "month_and_year") date_string = monthNames[user_date.getMonth()] + " " + user_date.getFullYear();
        else if (option === "date_with_day") date_string = `${day_name[day_id]} -- ${day}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()}`;
        
        return date_string;
    } else {
        return "--";
    }
};

export function showAlert(title, msg, type, btnName = "OK") {
    // type: error, success, warning, confirm
    return swal({
        title: title,
        text: msg,
        icon: type,
        button: btnName,
    });
}

export function showConfirm(title, msg, type, action) {
    // type: error, success, warning, confirm
    return swal({
        title: title,
        text: msg,
        icon: type,
        buttons: true,
        dangerMode: true,
    })
}

export function encryptData(string, val = false) {
    try {
        let secret_key = HOTEL_CODE + "_ENCRYPT";
        let secret_iv = HOTEL_CODE + "_ENCRYPT_IV";
        // hash
        let kee = CryptoJS.SHA256(secret_key);
        let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

        kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
        ivv = CryptoJS.enc.Utf8.parse(ivv);

        let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

        let result = decrypted.toString();
        return btoa(result);
    } catch (e) {
        console.log(e)
    }
}

export function decryptData(string, val = false) {
    try {
        let secret_key = HOTEL_CODE + "_ENCRYPT";
        let secret_iv = HOTEL_CODE + "_ENCRYPT_IV";
        // hash
        let kee = CryptoJS.SHA256(secret_key);
        let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

        kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
        ivv = CryptoJS.enc.Utf8.parse(ivv);

        var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (e) {
        console.log(e)
    }
}


export const date_days = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
]

export function convertTime(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}

const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
let hour = date.getHours();
let min = date.getMinutes();
let sec = date.getSeconds()
export const Renewal_date = "2024-8-15"
export const currentDate = `${year}-${month}-${day}`;
export const currentTime = `${hour}:${min}:${sec}`

export function loginValidation(page_url, permissionData) {
    const check_page_valid = permissionData.filter(permission => permission.sub_menu_link === page_url)
    if (check_page_valid.length < 1)
        return 'not permitted';
    else
        return 'permitted';
}

export const generate_token = (length) => {
    //edit the token allowed characters
    let a = "1234567890".split("");
    let b = [];
    for (let i = 0; i < length; i++) {
        let j = (Math.random() * (a.length - 1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}

export const num_of_days = (check_in_date, check_out_date) => {
    let difference = new Date(check_out_date).getTime() - new Date(check_in_date).getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
}
export const num_of_hours = (check_in_date, check_out_date) => {
    let difference = new Date(check_out_date).getTime() - new Date(check_in_date).getTime();
    return Math.ceil(difference / (1000 * 3600));
}
export const num_of_months = (check_in_date, check_out_date) => {
    return new Date(check_out_date).getMonth() - new Date(check_in_date).getMonth();
}



export const CheckNumbers = (str) => {
    if (str.match(/[^0-9]/)) {
        return true
    }else{
        return false
    }
}

export const Audit = (staff_id, message, header = '') =>
{
    axios.post(`${API_URL}settings/audit/add`, {employee_id: staff_id, action: message}, header).then((r) => { });
};

export const SalaryStepScale = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']
