import React, { useEffect } from "react";
import logo from "../../../../../src/assets/img/logo.png";
import "jquery/dist/jquery.min.js";
import jsZip from "jszip";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "./style.css";
import $ from "jquery";
import { HOTEL_NAME } from "../../../../context/connection-string";
import {
    COMPANY_PENSION_EMPLOYER_CODE,
    COMPANY_PFA,
    COMPANY_RECEIVING_ACCOUNT_NO,
    COMPANY_RECEIVING_BANK,
    COMPANY_SORT_CODE,
    COMPANY_TIN_NUMBER,
} from "../../../../context/function";

window.JSZip = jsZip;

export default function PensionDataTable({
                                             tableID = "table",
                                             date,
                                             caption,
                                             groupCol = 0,
                                             isGrouping = false,
                                             header = [],
                                             body,
                                         }) {
    const today = new Date();
    const current_date = today.toISOString().split("T")[0];

    const isGroupingConfig = isGrouping
        ? {
            order: [[groupCol, "asc"]],
            drawCallback: function (settings) {
                let api = this.api();
                let rows = api.rows({ page: "current" }).nodes();
                let last = null;

                api.column(groupCol, { page: "current" }).data().each((group, i) => {
                    if (last !== group) {
                        $(rows)
                            .eq(i)
                            .before(
                                `<tr class="group"><td colspan="14" style="background-color: rgba(110, 219, 110, 0.42);" align="center"><h4>${group}</h4></td></tr>`
                            );
                        last = group;
                    }
                });
            },
        }
        : {};

    const groupColumnVisibility = isGrouping ? { visible: false, targets: groupCol } : {};

    useEffect(() => {
        if (!$.fn.DataTable.isDataTable(`#${tableID}`)) {
            setTimeout(() => {
                $(`#${tableID}`).DataTable({
                    pagingType: "full_numbers",
                    pageLength: 20,
                    processing: true,
                    dom: "Bfrtip",
                    select: { style: "single" },
                    buttons: [
                        { extend: "pageLength", className: "btn btn-secondary bg-secondary" },
                        { extend: "copy", className: "btn btn-secondary bg-secondary" },
                        { extend: "csv", className: "btn btn-secondary bg-secondary" },
                        { extend: "excelHtml5", className: "btn btn-secondary bg-secondary" },
                        {
                            extend: "print",
                            customize: function (win) {
                                $(win.document.body).find("h1, td a").remove();
                                $(win.document.body)
                                    .find("table")
                                    .prepend(
                                        `<caption>
                      <div class="flex-container" style="width: 100%; margin: auto;">
                        <img src="https://payroll.teamcoded.com.ng/logo.png" width="100" height="80"/>
                        <div class="flex-item text-uppercase">
                          <h4>
                            <b>${HOTEL_NAME}</b>
                            <small>
                              <br>FOR THE PERIOD: ${date}
                              <br>PFA: ${COMPANY_PFA}
                              <br>RECEIVING BANK: ${COMPANY_RECEIVING_BANK}
                              <br>EMPLOYER'S CODE: ${COMPANY_PENSION_EMPLOYER_CODE}
                              <br>TOTAL AMOUNT: ${caption}
                              <br>RECEIVING ACCOUNT NUMBER: ${COMPANY_RECEIVING_ACCOUNT_NO}
                              <br>SORT CODE: ${COMPANY_SORT_CODE}
                            </small>
                          </h4>
                        </div>
                      </div>
                      <hr/>
                    </caption>`
                                    );
                                $(win.document.body)
                                    .find("th, td")
                                    .css({ "white-space": "pre-line", "border": "1px solid #eee" });
                                $(win.document.body)
                                    .find("table")
                                    .addClass("compact")
                                    .css({ fontSize: "11px", width: "100%", fontFamily: "Roboto, Helvetica, Arial, sans-serif" });
                            },
                            className: "btn btn-secondary bg-secondary",
                        },
                        {
                            extend: "print",
                            text: "Bulk Print",
                            className: "btn btn-secondary bg-secondary",
                            action: () => window.print(),
                        },
                    ],
                    ...isGroupingConfig,
                    fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
                        $("td:first", nRow).html(iDisplayIndexFull + 1);
                        return nRow;
                    },
                    lengthMenu: [
                        [10, 20, 30, 50, -1],
                        [10, 20, 30, 50, "All"],
                    ],
                    columnDefs: [
                        groupColumnVisibility,
                        {
                            targets: 0,
                            render: (data, type, row, meta) => (type === "export" ? meta.row + 1 : data),
                        },
                    ],
                });
            }, 5000);
        }
    }, [tableID]);

    return (
        <table
            id={tableID}
            className="table caption-top myTable align-items-center justify-content-center mb-0"
            style={{ fontSize: "12px" }}
        >
            <thead>
            <tr style={{ border: "1px solid #cccccc" }}>
                {header.map((item, index) => (
                    <th key={index} className="text-uppercase text-dark text-sm font-weight-bolder bg-secondary opacity-7 ps-2">
                        {item}
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>{body}</tbody>
        </table>
    );
}
